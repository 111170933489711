<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('policies.title')"></banner>
    <v-row class="ma-0 px-lg-16">
      <v-container fluid class="pa-lg-16 pa-10">
        <v-row>
          <v-col>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.generalDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.howWeUseYourInformation") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify mb-3"
              v-html="$t('policies.howWeUseYourInformationDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.howWeShareYourInformation") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify mb-3"
              v-html="$t('policies.howWeShareYourInformationDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.yourRights") }}</h2>
            <p
              class="p josefin-light black-grin text-justify mb-3"
              v-html="$t('policies.yourRightsDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.linksToThirdPartySites") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.linksToThirdPartySitesDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.dataSecurity") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.dataSecurityDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.otherImportantInformation") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.otherImportantInformationDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.howWeUseCookiesAndOthersTechnologies") }}
            </h2>
            <p class="p josefin-light black-grin text-justify">
              {{
                $t("policies.howWeUseCookiesAndOthersTechnologiesDescription")
              }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.cookies") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.cookiesDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.beacons") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.beaconsDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.targetedAdvertising") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.targetedAdvertisingDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.orderCancellationAndModificationPolicy") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="
                $t('policies.orderCancellationAndModificationPolicyDescription')
              "
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.paymentAndProcessingOfYourOrder") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.paymentAndProcessingOfYourOrderDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.minimumPurchaseAmount") }}.</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.minimumPurchaseAmountDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.ageRestriction") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.ageRestrictionDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.schedulesTimesAndDeliveryConditions") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="
                $t('policies.schedulesTimesAndDeliveryConditionsDescription')
              "
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">
              {{ $t("policies.jurisdictionAndApplicableLaw") }}
            </h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.jurisdictionAndApplicableLawDescription')"
            ></p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="h2 mb-3">{{ $t("policies.contact") }}</h2>
            <p
              class="p josefin-light black-grin text-justify"
              v-html="$t('policies.contactDescription')"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner,
  },
};
</script>
